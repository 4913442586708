// Custom fonts here
// Custom fonts here
@font-face {
    font-family: 'FoundersGroteskCond';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskCondensedWeb-Semibold.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskCondensedWeb-Semibold.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskCondensedWeb-Semibold.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskCondensedWeb-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'FoundersGroteskMono';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskMonoWeb-Regular.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskMonoWeb-Regular.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskMonoWeb-Regular.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/FoundersGroteskMonoWeb-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PublicoText';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Roman-Web.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Roman-Web.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Roman-Web.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Roman-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PublicoText';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Bold-Web.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Bold-Web.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Bold-Web.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Bold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PublicoText';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Italic-Web.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Italic-Web.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Italic-Web.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-Italic-Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'PublicoText';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-BoldItalic-Web.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-BoldItalic-Web.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-BoldItalic-Web.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoText-BoldItalic-Web.woff') format('woff');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'PublicoHeadline';
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoHeadline-Light-Web.eot');
    src: url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoHeadline-Light-Web.eot?#iefix') format('embedded-opentype'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoHeadline-Light-Web.woff2') format('woff2'), url('https://nodeassets.nbcnews.com/cdnassets/projects/fonts/PublicoHeadline-Light-Web.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
.scrim {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.scrim--thumbnail {
  background-color: #000;
  display: none;
  opacity: 0.35;

  @media (min-width: $screen-sm-min) {
    display: block;
  }
}

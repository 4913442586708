.dateline {
  font-family: $font-mono;
}

.dateline--block {
  display: block;
}

.dateline--entry {
  @include setType(2, $ms-down1);
}

.byline {
  @include setType(2, $ms-down1);

  font-family: $font-mono;
  margin-left: auto;
  margin-right: auto;
  max-width: $column-width + ($gutter * 2);
}

.byline__name {
  margin-right: $base-vertical-unit;
  text-transform: uppercase;
}

.caption {
  float: left;
  max-width: $column-width;
}

.caption__source {
  font-size: $caption-source-size;
  opacity: 0.7;
  font-family: $font-mono;
  word-spacing: -0.1em;
  display: inline-block;
}

.article__body > p:first-child::first-letter {
  display: block;
  float: left;
  font-size: 9.5em;
  font-weight: 900;
  line-height: 0.7;
  margin-right: $base-vertical-unit;
  margin-top: 0.08em;
}

.dropcap {
  display: block;
  float: left;
  font-size: 9.5em;
  font-weight: 900;
  line-height: 0.7;
  margin-right: $base-vertical-unit;
  margin-top: 0.08em;
}

.dropcap--sm {
  display: block;
  float: left;
  font-size: 3.45em;
  font-weight: 900;
  line-height: 0.7;
  margin-right: $gutter / 4;
}

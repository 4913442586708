//==============================================================================
// Config Variables
//==============================================================================
// The config variables have default values that are just looking to be adjusted.
// Use this variables to customize your Typesettings. Or take the whole thing
// apart if you want :)

// Font families and default text color.
$font-sans: "FoundersGroteskCond", "Helvetica Neue", Arial, sans-serif !default;
$font-headline: "FoundersGroteskCond", "Helvetica Neue", Arial, sans-serif !default;
$font-serif: "PublicoText", Georgia, "Times New Roman", serif !default;
$font-display-sans: "FoundersGroteskCond", "Helvetica Neue", Arial, sans-serif !default;
$font-display-serif: "PublicoHeadline", Georgia, "Times New Roman", serif !default;
$font-mono: "FoundersGroteskMono", Menlo, Monaco, monospace !default;
$font-generic-sans: "Helvetica Neue", Helvetica, Arial, sans-serif;

// The vertical grid unit. Margin, padding, and line-height are set to multiples
// of this value. This is the value that determines the baseline for our vertical
// rhythm. The default value of 6px allows more fine tuned designs that still
// obey vertical rhythm.
$base-vertical-unit: 4px;

// The value that multiplies the $base-vertical-unit to get the $base-line-height.
// This gives type an ideal line-height.
$base-line-multi: 7;

// Base font size in pixels.
$base-font-size: 17px;

// Modular scale ratio that is used to figure out all the different font sizes
$ms-ratio: 1.25;

// [1] Paragraph setting that you can turn on or off the indent of the first line
// of a new paragraph.
// [2] Paragraph setting that you can turn on or off to have your paragraphs ragged
// right or justified.
$paragraph-indent: false; // [1]
$paragraph-justify: false; // [2]

// You can optionally load type styles that use Typesettings.
$load-typesetted: false;
$global-init: true;

$caption-source-size: 0.88em;

// Colors
$dark: #000;
$dark-grey: #555;
$grey: #737373;
$light-grey: #bdbdbd;
$lighter-grey: #f0f0f0;

$background-color: #fff;

$highlight-color: #ebebeb;
$link-color: $dark-grey;
$text-color: $dark-grey;
$secondary-text-color: $grey;

// Borders
$border-width-narrow: 1px;
$border-width-normal: $base-vertical-unit;
$border-width-wide: $border-width-normal * 2;

// Breakpoints
$screen-xs-min: 640px;
$screen-sm-min: 740px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xs-max: ($screen-sm-min -1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);

// Layout Settings
$column-width: 640px;
$gutter: $base-vertical-unit * 2;

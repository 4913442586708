.standfirst {
  @include setType(6);

  margin-bottom: emRhythm(5);
  font-family: $font-display-serif;
  max-width: $column-width;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $screen-sm-min) {
    @include setType(7);

    margin-bottom: emRhythm(5);
  }
}

.story-header {
  color: #fff;
  height: 100vh;
  margin-bottom: $gutter * 4;
  overflow: hidden;
  position: relative;
}

.story-header--1-2 {
  height: 50vh;
}

.story-header--3-4 {
  height: 75vh;
}

.story-header h1 {
  @include setType(10, $ms-up4);

  font-family: $font-display-sans;
  max-width: $column-width;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $screen-sm-min) {
    @include setType(18, $ms-up7);

    margin-bottom: emRhythm(4, $ms-up7, $silent: true);
  }
}

.story-header__intro {
  bottom: emRhythm(8);
  padding-left: $gutter;
  padding-right: $gutter;
  position: absolute;
  text-align: center;
  transition: opacity 0.5s ease-in;
  width: 100%;
  z-index: 2;
}

.story-header__bg {
  left: 50%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;

  &::-webkit-media-controls,
  &::-webkit-media-controls-start-playback-button {
    display: none;
  }
}

.story-header__bg {
  left: 50%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;

  @supports (object-fit: cover) {
    left: 0;
    min-width: 0;
    min-height: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transform: translateX(0);
  }

  &::-webkit-media-controls,
  &::-webkit-media-controls-start-playback-button {
    display: none;
  }
}

.offcanvas {
  background-color: $background-color;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  height: 100%;
  left: -100%;
  max-width: 400px;
  padding: ($gutter * 2) $gutter $gutter $gutter;
  position: fixed;
  top: 0;
  transition: left 0.2s ease-out;
  width: 100%;
  z-index: 7;
}

.offcanvas.is-active {
  left: 0;
}

[data-sset="offcanvas"] {
  cursor: pointer;
  text-decoration: underline;

  &:active {
    opacity: 0.8;
  }

  &::before {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iVGV4dF9kb2N1bWVudCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjAgMjAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik0xNiwxSDRDMy40NDcsMSwzLDEuNDQ3LDMsMnYxNmMwLDAuNTUyLDAuNDQ3LDEsMSwxaDEyYzAuNTUzLDAsMS0wLjQ0OCwxLTFWMkMxNywxLjQ0OCwxNi41NTMsMSwxNiwxeiBNMTUsMTdINVYzaDEwVjE3eiBNMTMsNUg3djJoNlY1eiBNMTMsMTNIN3YyaDZWMTN6IE0xMyw5SDd2Mmg2Vjl6Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    height: 15px;
    margin-right: 2px;
    width: 15px;
  }
}

.offcanvas__close {
  border: 0;
  position: absolute;
  right: 0;
  top: $gutter / 2;

  svg {
    fill: $text-color;
    width: 20px;
    height: 20px;
  }
}

.offcanvas__scroll {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding: ($gutter * 2) $gutter $gutter $gutter;
}

aside .offcanvas__scroll {
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
  }
}

// 3rd party
// @import url("https://cdnjs.cloudflare.com/ajax/libs/video.js/5.20.1/video-js.min.css");
// @import url('https://fonts.googleapis.com/css?family=Archivo+Black|Archivo+Narrow:400,700|Merriweather:300,300i,400,700,700i,900');

// Configurations
@import "config";

// Fonts
@import "components/fonts";

// Dependencies
@import "../../node_modules/typesettings/typesettings";

// Reset
@import "vendor/minireset";

// Functions
//

// Mixins
@import "mixins/clearfix";
@import "mixins/fluid-type";
@import "mixins/generate-ratios";

// Utilities
@import "utilities/align";
@import "utilities/clearfix";
@import "utilities/intrinsic";
@import "utilities/position";
@import "utilities/ratios";

// Base
@import "base";

// Components
@import "components/addendum";
@import "components/animate";
@import "components/ai2html";
@import "components/byline";
@import "components/callout";
@import "components/captions";
@import "components/blockquote";
@import "components/chart";
@import "components/credits";
@import "components/dateline";
@import "components/dropcap";
@import "components/entry";
@import "components/kicker";
@import "components/lazysizes";
@import "components/offcanvas";
@import "components/lead";
@import "components/media";
@import "components/pullquote";
@import "components/reveal";
@import "components/scrim";
@import "components/standfirst";
@import "components/story-header";
@import "components/story";
@import "components/tombstone";
@import "components/tweet";
@import "components/video-default-js";

// Layout
@import "layout/column";
@import "layout/gutter";
@import "layout/hang";
@import "layout/set";
@import "layout/viewport";

// States
@import "states";

body {
    background-color: black;
}

.logo {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    max-width: 80%;
    height: 2rem;
    width: auto;
    @media (min-width: $screen-lg-min) {
        height: 3.75rem;
    }
}

.section-wrapper  {
    flex-wrap: wrap;
    @media (min-width: $screen-sm-min) {
        flex-wrap: nowrap;
    }
}

.section:nth-child(even) {
    .section-wrapper {
        flex-wrap: wrap;
        @media (min-width: $screen-sm-min) {
            flex-wrap: nowrap; 
            flex-direction: row-reverse; 
        }
    }
}

img.phone {
    padding: 0 9%;
    height: 207.46%;
    max-width: 500px;
    width: 80%;
    @media (min-width: $screen-sm-min) {
        width: 90%;
    }
    @media (min-width: $screen-md-min) {
        width: 100%;
    }
}

img.video-phone {
    padding: 0 9%;
    height: 207.46%;
    max-width: 500px;
    width: 80%;
    @media (min-width: $screen-sm-min) {
        width: 90%;
    }
    @media (min-width: $screen-md-min) {
        width: 100%;
    }
}

.video-wrapper {
    position: absolute;
    padding: 0 9%;
    max-width: 500px;
    width: 80%;
    @media (min-width: $screen-sm-min) {
        width: 90%;
    }
    @media (min-width: $screen-md-min) {
        width: 100%;
    }
    top: 46%;
    -webkit-transform: translateY(-46%);
    transform: translateY(-46%);    
}

.video-js {
    padding: 0 5%;
    display: block;
    margin: 0 auto;
    width: 100%;
}

// Download badges
.badges a {
    display: inline-block;
    height: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    margin-right: 0.5em;
    width: 122px;
    padding-top: 36px;
    background-size: 122px 36px;
    @media (min-width: $screen-sm-min) {
        width: 135px;
        padding-top: 40px;
        background-size: 135px 40px;
    }
}
.badge-app-store {
    background-image: url('../assets/app-store-badge-o.svg');
    background-image: url('../assets/app-store-badge.svg');
}
.badge-app-store:hover {
    background-image: url('../assets/app-store-badge-o.svg');
}
.badge-google-play {
    background-image: url('../assets/google-play-badge-o.svg');
    background-image: url('../assets/google-play-badge.svg');
}
.badge-google-play:hover {
    background-image: url('../assets/google-play-badge-o.svg');
}
.bg-white {
    .badge-app-store {
        background-image: url('../assets/app-store-badge-white-o.svg');
        background-image: url('../assets/app-store-badge-white.svg');
    }
    .badge-app-store:hover {
        background-image: url('../assets/app-store-badge-white-o.svg');
    }
    .badge-google-play {
        background-image: url('../assets/google-play-badge-white-o.svg');
        background-image: url('../assets/google-play-badge-white.svg');
    }
    .badge-google-play:hover {
        background-image: url('../assets/google-play-badge-white-o.svg');
    }
}

.no-observer .animate {
    opacity: 1;
    h2, p, .figure, .badges, .logo {
        opacity: 1;
        transform: none;
    }
}

.animate, .animate:nth-child(1), .animate:nth-child(2) {
    h2, .logo {
        opacity: 0;
        transition: opacity, transform 1.6s ease-out;
        transform: translateY(30px);
    }
    p, .badges {
        opacity: 0;
        transition: opacity, transform 1.8s ease-out;
        transform: translateY(40px);
    }
    .figure {
        opacity: 0;
        transition: opacity 2.2s ease-out;
    }
}

.animate.download {
    opacity: 0;
    transition: opacity, transform 2.6s ease-out;
}
.animate.is-active {
    opacity: 1;
    p, h2, .figure, .badges, .logo {
        opacity: 1;
        transform: none;
    }
}


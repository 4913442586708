.kicker {
  @include setType(4, $ms-down1);

  font-family: $font-mono;
  text-transform: uppercase;
}

.kicker--hed {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: emRhythm(2, $ms-down1);
  max-width: $column-width + ($gutter * 2);
}

.kicker--inline {
  color: $text-color;
}

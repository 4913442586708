.lead {
  align-items: center;
  margin-bottom: $gutter;
  width: 100%;

  @media (min-width: $screen-xs-min) {
    display: flex;
  }

  a,
  a:link {
    background-image: none;
    color: $text-color;
    text-shadow: none;
    transition-duration: 0.5s, 0.5s;
    transition-property: fill, color;
    transition-timing-function: ease, ease;
  }

  a:hover,
  a:active,
  a:hover svg,
  a:active svg {
    color: lighten($dark-grey, 35%);
    fill: lighten($dark-grey, 35%);
    text-decoration: none;
  }
}

.lead__headline {
  @include setType(4, $ms-up2);

  margin-bottom: 0;
}

.lead__desc {
  margin-bottom: 0;
}

.lead__art {
  margin-bottom: emRhythm(1);

  @media (min-width: $screen-xs-min) {
    flex-basis: 50%;
    margin-bottom: 0;
    max-width: 50%;
    padding-right: $gutter / 2;
  }

  @media (min-width: $screen-sm-min) {
    flex-basis: 66.66%;
    max-width: 66.66%;
  }
}

.lead__art img {
  display: block;
  height: auto;
  max-width: 100%;
}

.lead__text {
  @media (min-width: $screen-xs-min) {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: $gutter / 2;
  }

  @media (min-width: $screen-sm-min) {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
}

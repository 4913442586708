.media {
  margin-bottom: emRhythm($base-line-multi);
  width: 100%;
}

.media img {
  display: block;
  max-width: 100%;
}

.media--diptych {
  img,
  video,
  figure {
    margin-bottom: $base-vertical-unit;

    @media (min-width: $screen-xs-min) {
      float: left;
      width: 50%;

      &:nth-of-type(2n) {
        padding-left: $gutter / 2;
      }

      &:nth-of-type(1) {
        padding-right: $gutter / 2;
      }
    }
  }
}

.media--video-embed {
  @extend .ratio--16x9;
  @extend .intrinsic;

  width: 100%;
  z-index: 1;

  iframe,
  video {
    @extend .intrinsic__element;
  }
}

.media--video-embed--player {
  background-color: transparent;
}

.media__gallery {
  @media (min-width: $screen-xs-min) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    & + figcaption {
      margin-top: $gutter / -2;
    }
  }
}

.media__gallery__item {
  padding-bottom: $gutter;
}

.media__gallery .media__gallery__item--wide figcaption {
  @media (min-width: ($column-width * 2) + $gutter) {
    padding-left: 0;
    padding-right: 0;
  }
}

.media__gallery .media__gallery__item--full figcaption {
  @media (min-width: ($column-width * 1) + $gutter) {
    padding-left: 0;
    padding-right: 0;
  }
}

.media__gallery .media__gallery__item--inline {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
}

.media__gallery__item--image {
  &:hover {
    cursor: pointer;
    cursor: zoom-in;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
  }
}

.media__gallery__item {
  background-image: none;
  display: block;

  &:last-of-type {
    padding-bottom: 0;
  }

  .ie9 & {
    margin-bottom: $gutter * 2;
  }
}

.media__gallery__item--2 {
  $calc-gutter: $gutter / 2;

  @media (min-width: $screen-xs-min) {
    width: calc(50% - #{$calc-gutter});
  }
}

.media__gallery__item--3 {
  $calc-gutter: ($gutter / 3) * 2;

  @media (min-width: $screen-xs-min) {
    width: calc(33.333333% - #{$calc-gutter});
  }
}

.media__gallery__item--2-tweet {
  $calc-gutter: $gutter / 2;

  width: 100%;

  @media (min-width: 760px) {
    width: calc(50% - #{$calc-gutter});
  }
}

.media__gallery__item--3-tweet {
  $calc-gutter: ($gutter / 3) * 2;

  width: 100%;

  @media (min-width: 760px) {
    width: calc(33.333333% - #{$calc-gutter});
  }
}

.media__title {
  @include setType(7, $ms-up2);

  font-family: $font-sans;
}

.media__subtitle {
  color: $grey;
  display: block;
  font-weight: normal;
  margin-top: emRhythm(2, $ms-up1);
}

.media__leadin {
  @include setType(7, $base-em-font-size, $silent: true);

  color: $grey;
  margin-left: auto;
  margin-right: auto;
  max-width: $column-width;
  padding-left: $gutter;
  padding-right: $gutter;
}
